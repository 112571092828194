<template>
  <div class="abanner">
    <el-form label-width="120px" label-position="right" ref="form" :model="form" :rules="ruleForm">
      <el-form-item label="图片名称" prop="bannerName">
        <el-input placeholder="" v-model="form.bannerName" class="public-input"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="bannerImg">
        <el-upload action="" accept="image/*" :auto-upload="false" :show-file-list="false" :on-change="upChange">
          <img v-if="form.bannerImg" :src="form.bannerImg" alt="">
          <myButton v-else title="选择图片"/>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接">
        <el-input placeholder="输入链接地址" class="public-input" v-model="form.bannerUrl"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="bannerSort">
        <el-input placeholder="" type="number" class="public-input" v-model="form.bannerSort"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="bannerStatus">
        <el-select v-model="form.bannerStatus" placeholder="">
          <el-option :value="20" label="禁用"></el-option>
          <el-option :value="10" label="启用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <myButton title="提交" @myclick="submit" type="primary" style="width: 65px"/>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import analyze from 'rgbaster'
export default {
  data() {
    let checkImg = (rule, value, fn) => {
      console.log(rule)
      console.log(this.bannerImg)
      if (!value) {
        return fn(new Error('请上传图片'))
      }
      return fn()
    }
    return {
      imageList: [],
      imgUrl: "",
      form: {
        bannerName: '',
        bannerImg: '',
        bannerUrl: '',
        bannerSort: '',
        bannerStatus: '',
        bannerColour: '#FFFFFF'
      },
      ruleForm: {
        bannerName: [
          {required: true, message: '请输入banner名', trigger: 'blur'}
        ],
        bannerImg: [
          {required: true, validator: checkImg, trigger: 'change'}
        ],
        bannerSort: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ],
        bannerStatus: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ]
      },
      appUrl: '',
      type: '',
    };
  },
  created() {
    this.appUrl = process.env.VUE_APP_URL;
    this.type = this.$route.query.type;
    if (this.type == 20) {
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    upChange(file, fileList) {
      var reader = new FileReader();
      reader.onload =  async (e) => {
        //获取图片颜色组
        const result = await analyze(e.target.result)
        this.form.bannerColour = result[0].color
      }
      reader.readAsDataURL(file.raw);

      this.imageList = fileList;
      let obj = new FormData();
      obj.append('file', file.raw)
      this.$request.HttpPost('/admin/upload/upload', obj)
          .then(res => {
            this.form.bannerImg = this.appUrl + '/' + res.data.file.url;
            // this.$refs.form.validate()
          })
    },
    getDetail(id) {
      this.$request.HttpGet('/admin/cmsBanner/find', {id})
          .then(res => {
            this.form = res.data || {}
          })
    },
    submit() {

      console.log(this.type)
      if (this.type == 10) {
        this.addBanner()
      } else {
        this.editBanner()
      }
    },
    addBanner() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.HttpPost('/admin/cmsBanner/create', this.form)
              .then(res => {
                this.$plugin.notifySuccess('添加成功')
                this.$router.back()
              })
        }
      })
    },
    editBanner() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$request.HttpPost('/admin/cmsBanner/update', this.form)
              .then(res => {
                this.$plugin.notifySuccess('修改成功')
                this.$router.back()
              })
        }
      })
    }
  }
};
</script>
<style lang='scss' scoped>
img {
  width: 100px;
  height: 100px;
}
</style>
